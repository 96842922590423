@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  font-style: normal;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  user-select: none;
}
:before,
:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  /* font-family: "Inter", sans-serif;
	 */
  font-family: "Lato", sans-serif;
  overflow: hidden;
}
/* Lists==================== */
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* Links, inputs and Buttons==================== */
a {
  color: inherit;
  display: inline-flex;
}
a:hover {
  color: #ccc;
}
a,
.btn,
.button,
button,
input[type="button"],
input[type="button"],
input[type="submit"],
input[type="submit"],
select {
  outline: none;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  cursor: pointer;
}
input,
textarea,
select {
  font-size: 16px;
  padding: 3px 10px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #ccc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
figure {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
select {
  -webkit-appearance: none;
}
input::-ms-clear,
input:-ms-clear {
  display: none;
}
textarea {
  resize: none;
  padding: 5px 10px;
}
:disabled,
::disabled {
  cursor: not-allowed;
}
:readonly,
::readonly {
  cursor: default;
}
::-webkit-input-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}
::-moz-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}
:-ms-input-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}
::-ms-input-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}
::placeholder {
  font-size: inherit;
  color: #bbb9d1;
}
/* Tables==================== */
table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
td {
  vertical-align: middle;
}
/* Headings==================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
/* Other elements==================== */
img {
  max-height: 100%;
  max-width: 100%;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}
p + p {
  margin-top: 15px;
}
br,
.clear {
  clear: both;
}
audio,
video {
  display: block;
  width: 100%;
}
/****OPTIONAL**** Common classes one can use for different purposes **************/
.btn {
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.btn-theme {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  height: 60px;
  border-radius: 10px;
}
.clearfix:after {
  clear: both;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.f-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.f-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.f-justify-between {
  justify-content: space-between;
}
.f-1 {
  flex: 1 1 auto;
}
.o-h {
  overflow: hidden;
}
.o-a {
  overflow: auto;
}
.f-column {
  flex-direction: column;
}
.f-wrap {
  flex-wrap: wrap;
}
.h-100 {
  height: 100%;
}
.vh-100 {
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.text-blue {
  color: #06f;
}
.btn-blue {
  background-color: #06f;
  color: #fff;
}
.btn-blue:hover {
  background-color: #1847a5;
}
.btn-gradient {
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
.btn-gradient:hover {
  background: linear-gradient(45.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
  background-color: #1a68ff;
}
.filled-ico {
  display: none;
}
.header-content h3 {
  display: flex;
  align-items: center;
  color: #4d5e80;
}
.header-content-container h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.005em;
}
.subHeader-content-container h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
}
.header-content {
  max-width: 100%;
  display: flex;
  align-items: center;
}
.header-content-options {
  margin-left: auto;
}
.num-header {
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  border-radius: 100px;
  padding-inline: 10px;
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 24px;
  margin-left: 10px;
}
.header-content-container {
  /* margin-bottom: 40px;
	 */
  /* margin-bottom: 30px;
	 */
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #e4e8ec;
}
.header-content-container .custom-select select {
  height: 41px;
  color: #1877f2;
  background-image: url("../../../public//images/arrow-down-blue.png");
  box-shadow: 0px 2px 8px 0px #000 1A;
}
.custom-select select {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}
.custom-check {
  cursor: pointer;
  position: relative;
}
.custom-check input {
  display: none;
}
.custom-check input:checked + .check-style {
  background: #00a337;
}
.custom-check input + .check-style {
  height: 18px;
  width: 36px;
  background: #ccc;
  border-radius: 50px;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.custom-check input + .check-style ~ .check-text {
  vertical-align: middle;
  margin-left: 10px;
}
.custom-check input:checked + .check-style:before {
  left: calc(100% - 15px);
}
.custom-check input + .check-style:before {
  content: "";
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 4px #000;
  border-radius: 50px;
  height: 12px;
  width: 12px;
  left: 3px;
  top: 3px;
}
.custom-check input:checked + .check-style ~ .check-text:not(.no-text):before {
  content: "On";
}
.custom-check input + .check-style ~ .check-text:not(.no-text):before {
  content: "Off";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  color: #fff;
}
.no-shadow {
  box-shadow: none !important;
}
.boxed {
  background: #fff;
  border: 1px solid #b7c3d2;
  box-shadow: 0px 4px 30px rgba(93, 95, 239, 0.1);
  border-radius: 20px;
}
.boxed-padding {
  padding: 20px 22px;
}
.loader-inline {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #7d8fb3;
  margin-top: 19px;
  text-align: center;
}
/* Auth */
.auth-forms-container {
  flex: 0 0 888px;
  position: relative;
  box-shadow: 0px 147px 30px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: auto;
}
.auth-images-container {
  flex: 1 1 auto;
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
}
.auth-header {
  width: 100%;
  max-width: 982px;
  margin-left: auto;
  margin-top: 30px;
}
.auth-text-container {
  width: 100%;
  max-width: 882px;
  margin-left: auto;
  margin-top: 87px;
}
.auth-assets-container {
  width: 817px;
  height: 610px;
  margin-left: auto;
  pointer-events: none;
  margin-top: auto;
  padding-top: 45px;
}
.auth-fb-stories {
  position: absolute;
  top: 64px;
  right: -46px;
  z-index: 0;
}
.auth-dots {
  position: absolute;
  top: 421px;
  right: 722.25px;
}
.auth-waterfall {
  position: absolute;
  right: 913px;
  bottom: 238px;
}
.auth-dashboard-group {
  position: relative;
}
.auth-dashboard-group img {
  max-width: inherit;
  max-height: inherit;
}
.auth-dashboard-group .auth-dashboard-logo {
  position: absolute;
  top: 7px;
  left: 15px;
  max-width: 75px;
}
.auth-dashboard {
  margin-top: -50px;
  margin-left: -50px;
}
.auth-dashboard-icon-1,
.auth-dashboard-icon-2 {
  position: absolute;
}
.auth-dashboard-icon-1 {
  left: -45px;
  bottom: 118px;
}
.auth-dashboard-icon-2 {
  top: -45px;
  right: 200px;
}
.auth-text-container h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #fff;
  margin-bottom: 18px;
}
.auth-text-container p {
  max-width: 481px;
  line-height: 22px;
  color: #fff;
  opacity: 0.6;
}
.auth-footer {
  margin-top: auto;
  width: 100%;
  max-width: 828px;
  text-align: right;
  margin-bottom: 37px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.auth-forms-content {
  margin: auto;
  width: 100%;
  max-width: 588px;
}
.login-form-container,
.reset-form-container {
  margin-top: 52px;
}
.header-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.header-text p {
  font-size: 18px;
  line-height: 27px;
  color: #191a25;
  font-weight: 400;
  margin-top: 30px;
}
.form-label {
  display: block;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}
.form-control {
  width: 100%;
  background: #f9faff;
  border: 1px solid #cbd1dd;
  border-radius: 10px;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 19px;
  color: #424a5a;
}
input.form-control:focus,
textarea.form-control:focus {
  border-color: #1877f2;
}
.form-group + .form-group:not(.form-footer) {
  margin-top: 20px;
}
.form-group {
  position: relative;
}
.form-footer {
  margin-top: 33px;
}
.form-clickable {
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #06f;
}
.form-element {
  position: relative;
}
.form-clickable-reset {
  position: absolute;
  right: 20px;
  top: 20px;
}
.form-element-padding input {
  padding-right: 150px;
}
.custom-checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox-input {
  height: inherit;
  display: none;
}
.custom-checkbox-input + .custom-checkbox-content .custom-checkbox-figure {
  width: 18px;
  height: 18px;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid #000;
  margin-right: 13px;
}
.custom-checkbox-input:checked
  + .custom-checkbox-content
  .custom-checkbox-figure {
  background-image: url("../../../public/images/form-check.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  border-color: #06f;
}
.custom-checkbox-text {
  display: inline-flex;
  vertical-align: middle;
  font-size: 16px;
  line-height: 19px;
}
.custom-checkbox-content {
  font-size: 0;
}
.form-footer-addition {
  margin-top: 23px;
}
.auth-footer p {
  font-size: 14px;
  line-height: 17px;
  color: #4d5e80;
}
.auth-footer p a {
  color: #1877f2;
}
.auth-reset-success {
  color: #009718;
}
.auth-reset-success h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 37px;
}
.auth-reset-success p {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #009718;
  max-width: 340px;
  margin: 15px auto 0;
}
.auth-reset-success .btn {
  margin-top: 42px;
}
.error .form-control:not([type="checkbox"]):not([type="radio"]) {
  border: 1px solid #f54040;
  background: #000;
  color: #f54040;
}
.error-message {
  color: #f54040;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 100%;
  width: 100%;
}
.modal-general {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2a3038;
  opacity: 0.6;
}
.modal-outer {
  background: #fff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
@media (max-height: 991px) {
  .modal-outer {
    top: 110px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }
}
.modal-outer-changePassword {
  max-width: 708px;
}
.modal-header {
  background: #1877f2;
  color: #fff;
  height: 60px;
  padding-right: 65px;
  padding-left: 30px;
}
.btn-close-modal {
  height: 24px;
  display: flex;
  width: 24px;
  background: transparent;
  right: 30px;
  position: absolute;
}
.modal-header h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
}
.modal-body {
  padding: 60px;
  max-height: 72vh;
  overflow: auto;
}
.content-container {
  position: relative;
  z-index: 0;
}
.side-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9;
}
.side-modal-overlay {
  background: #2a3038;
  opacity: 0.6;
  position: absolute;
  inset: 0;
}
.side-modal-outer {
  position: absolute;
  inset: 0;
  left: auto;
  width: 69%;
  max-width: 1322px;
  background: #f7f8fa;
}
.side-modal-shrinked {
  width: 100%;
  max-width: 700px;
}
.side-modal-header {
  background: #1a68ff;
  flex: 0 0 60px;
  padding-inline: 50px;
}
.side-modal-header h4 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #fff;
}
.scroll-ui-thick::-webkit-scrollbar {
  width: 10px;
}
.scroll-ui-thick::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}
.scroll-ui-thick::-webkit-scrollbar-thumb {
  background-color: #bfc8dc;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.table {
  display: table;
  width: 100%;
}
.table-header {
  display: table-header-group;
}
.table-row {
  display: table-row;
}
.table-body {
  display: table-row-group;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
  word-break: break-word;
  word-wrap: break-word;
}
.table-sortable {
  table-layout: fixed;
  border-spacing: 0 5px;
}
.table .table-row:not(.table-header .table-row) {
  background: #fff;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
}
.table .table-row:not(.table-header .table-row).active {
  background: #eff4fd;
}
.table .table-row.active .table-cell {
  border-top: 1px solid #1968ff;
  border-bottom: 1px solid #1968ff;
}
.table .table-row.active .table-cell:first-child {
  border-left: 1px solid #1968ff;
}
.table .table-row.active .table-cell:last-child {
  border-right: 1px solid #1968ff;
}
.table .table-cell {
  height: 90px;
  padding-inline: 30px;
}
.table-sortable .table-header .table-cell {
  height: 55px;
}
.header-sort {
  display: inline-flex;
  vertical-align: middle;
  font-size: 0;
  line-height: 1;
  cursor: pointer;
  margin-left: 10px;
}
.side-modal-body.scroll-ui-thick {
  padding: 0 0 0 50px;
  margin-right: 23px;
}
.table .table-body .table-cell:first-child {
  border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
}
.table .table-body .table-cell:last-child {
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
}
/* DASHBOARD */
.header {
  z-index: 1;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  /* flex: 0 0 71px;
	 */
  flex: 0 0 90px;
  padding-inline: 30px 50px;
  border-bottom: 1px solid #bfc8dc;
}
.user-options-header {
  position: relative;
}
.user-options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 175px;
  background: #fff;
  padding: 8px 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px #000;
}
.user-options-menu .btn {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  height: 36px;
  padding-inline: 10px;
}
.user-options-menu .btn:hover {
  background: #1877f2;
  color: #fff;
}
.user-options-trigger {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 5px solid #ddd;
  margin-inline-start: 20px;
}
.navigation-menu-left .active img:not(.filled-ico),
.navigation-menu-left li a:hover img:not(.filled-ico) {
  display: none;
}
.navigation-menu-left .active .filled-ico,
.navigation-menu-left li a:hover .filled-ico {
  display: block;
}
.main-container:not(.auth-container) {
  background: #f7f8fa;
}
.navigation-menu-left {
  flex: 0 0 100px;
  padding-top: 60px;
  background: #fff;
  border-right: 1px solid #e0e4ea;
}
.navigation-menu-left ul {
  text-align: center;
}
.navigation-menu-left li {
  font-size: 0;
  line-height: 100%;
}
.navigation-menu-left li + li {
  margin-top: 50px;
}
.navigation-menu-left li a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-menu-left li a img {
  width: 36px;
}
.content-addition {
  /* flex: 0 0 547px;
	 */
  flex: 0 0 535px;
  margin-left: auto;
  padding: 20px 0 20px 48px;
  border-left: 1px solid #e0e4ea;
  overflow: auto;
  background-color: #fff;
}
.content-addition::-webkit-scrollbar,
.content-space::-webkit-scrollbar {
  width: 10px;
}
.content-addition::-webkit-scrollbar-track,
.content-space::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}
.content-addition::-webkit-scrollbar-thumb,
.content-space::-webkit-scrollbar-thumb {
  background-color: #bfc8dc;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.header-content h3 figure {
  font-size: 0;
  line-height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.content-addition .subHeader-content-container {
  /* margin-bottom: 28px;
	 */
  padding-bottom: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e8ec;
}
.select-range-insights select {
  padding-inline: 15px 33px;
  height: 30px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 2px 10px #000;
  background-image: url("../../../public/images//arrow-down-dark.png");
  background-position: right 11px center;
  background-repeat: no-repeat;
  line-height: 1;
}
.additional-info-component {
  margin-right: 48px;
}
.additional-info-component + .additional-info-component {
  margin-top: 20px;
}
.insights-aside {
  column-gap: 6px;
  row-gap: 9px;
}
.insights-element {
  flex: 1 1 calc(50% - 6px);
  display: flex;
  align-items: center;
  /* align-items: flex-start;
	 */
  justify-content: center;
  flex-direction: column;
  background: #1877f2;
  /* background-color: #5F83B1;
	 */
  border: 1px solid #acd7ff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 133px;
  color: #fff;
  padding: 15px;
}
.clickable-insights {
  cursor: pointer;
}
.clickable-insights:hover {
  opacity: 0.7;
}
.insights-element header {
  margin-bottom: 15px;
}
.insights-element header h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.005em;
}
.insights-element p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #cce7ff;
}
.insights-element ul,
.insights-element li,
.insights-element .more-engagers {
  display: inline-flex;
}
.insights-element li,
.insights-element .more-engagers {
  width: 50px;
  height: 50px;
  border: 3px solid #fff;
  border-radius: 50%;
  vertical-align: top;
  align-items: center;
  justify-content: center;
}
.insights-element li:not(:first-child),
.insights-element .more-engagers {
  margin-left: -18px;
}
.insights-element .more-engagers {
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.stories-container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px;
	 */
  gap: 23px;
  flex: 1 1 auto;
  /* overflow: auto;
	 */
}
.stories-container .stories {
  /* width: 129px;
	 */
  /* width: 216px;
	 */
  /* width: 225px;
	 */
  /* height: 200px;
	 */
  width: calc(20% - 23px);
  aspect-ratio: 0.6736526946;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1599px) {
  .stories-container.stories-engagement {
    gap: 15px;
  }
  .stories-container.stories-engagement .stories {
    width: calc(20% - 15px);
    height: inherit;
  }
}
@media (max-width: 1199px) {
  .stories-container.stories-engagement .stories {
    width: calc(15% - 15px);
    height: inherit;
  }
}
.aside-top-stories .stories {
  width: 129px;
  height: 200px;
  position: relative;
}
.stories header {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.bodyStory {
  height: 100%;
  width: 100%;
}
.timestamp {
  background: rgba(0, 0, 0, 0.72);
  border: 0.5px solid #fff;
  box-sizing: border-box;
  border-radius: 43px;
  display: inline-flex;
  height: 20px;
  padding: 0 9px;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #fff;
  line-height: 17px;
  pointer-events: all;
}
.stories header .views {
  pointer-events: all;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: #fff;
  padding-inline: 5px;
  background: rgba(0, 0, 0, 0.72);
  border: 0.5px solid #fff;
  border-radius: 30px;
}
.views figure {
  margin-right: 5px;
}
.views figure svg {
  max-width: 15px;
}
.body-story {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.body-story figure {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.footer-story {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer-story .btn {
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: #fff;
  text-shadow: 0px 1px 1px #000;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: space-between;
  width: 100%;
  line-height: 40px;
}
.btn.btn-setup a {
  width: 100%;
  display: flex;
  align-items: center;
}
.btn-setup {
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  border-radius: 0 0 10px 10px;
}
.btn-setup span {
  line-height: 100%;
  vertical-align: middle;
  margin-inline-start: auto;
}
.stories.set .footer-story {
  padding: 14px;
  line-height: 100%;
}
.content-space {
  padding: 30px 40px 30px 50px;
  overflow: auto;
  position: relative;
}
@media (max-width: 1600px) {
  .content-space {
    padding: 15px;
  }
}
.container-fixed-header .header-content-container {
  margin-right: 40px;
}
.container-fixed-header .content-space {
  padding-right: 0;
  overflow: hidden;
}
.container-fixed-header .dashboard-content {
  overflow: auto;
  padding-right: 10px;
}
.aside-top-stories {
  column-gap: 8px;
}
.top-stories-dashboard .header-content h3 {
  font-size: 18px;
  color: #000;
}
.top-stories-dashboard .stories header {
  padding-inline: 8px;
}
.engagement-insights-dashboard .header-content h3 {
  font-size: 16px;
  color: #000;
  line-height: 19px;
}
.engagement-insights-dashboard .ico-chart-aside img {
  width: 46px;
  max-width: inherit;
}
.insights-bar {
  background: #eee;
  border-radius: 28px;
  height: 10px;
  margin-top: 8px;
  display: flex;
  overflow: hidden;
}
.progress-insight {
  background: #5d5fef;
  display: inline-flex;
  font-size: 0;
  border-radius: 28px;
}
.aside-insights label {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.aside-insights + .aside-insights {
  margin-top: 18px;
}
.success-space p {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #009718;
  margin-top: 20px;
}
.success-space .btn {
  margin-top: 60px;
}
.changePassword-modal {
  font-family: "Inter", sans-serif;
}
/* DASHBOARD */
/* TOP ENGAGERS */
.table-user-image {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  position: relative;
  margin-right: 20px;
}
.table-cell-header {
  font-size: 14px;
  line-height: 17px;
  color: #4d5e80;
}
.table-cell-textBody h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.table-cell-textBody p {
  margin-top: 5px;
}
.table-cell-textBody a {
  display: inline-flex;
  align-items: center;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #7d8fb3;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
}
.table-cell-textBody a:hover {
  color: #1877f2;
}
.table .table-row.active .table-cell-textBody a {
  color: #1877f2;
}
.table-cell-textBody a svg {
  margin-left: 5px;
}
.open-link {
  margin-top: 2px;
}
.table-cell-textBody a:hover .open-link path:first-child,
.table .table-row.active .table-cell-textBody .open-link path:first-child {
  fill: #1877f2;
}
.table-cell-textBody a:hover .open-link path:last-child,
.table .table-row.active .table-cell-textBody .open-link path:last-child {
  stroke: #1877f2;
}
.table-cell-content {
  align-items: center;
}
.table-cell-content > p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.top-engagers-modal .table-sortable .table-cell:first-child {
  max-width: 720px;
  min-width: 374px;
}
.side-modal-inner .table-sortable {
  table-layout: auto;
}
.side-modal-body .side-modal-inner {
  margin-right: 17px;
  padding-bottom: 44px;
}
.user-status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  position: absolute;
  border: 2px solid #fff;
  background-color: #e3e3e3;
}
.user-status.available {
  background: #0d6;
}
.active-tick {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 10px;
  background-image: url("../../../public/images/tick-blue.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.btn-header-main {
  height: 50px;
  padding-inline: 24px;
  display: inline-flex;
  align-items: center;
  margin-inline-start: 10px;
}
.btn-header-main span {
  font-size: 0;
  line-height: 1;
  margin-right: 10px;
  position: relative;
}
.btn-sync-title span svg {
  transform-origin: 50% 50%;
}
.btn-sync-title.syncing span svg {
  animation: rotateSync 30s infinite alternate;
  -webkit-animation: rotateSync 30s infinite alternate;
}
@keyframes rotateSync {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: calc(720deg * 5);
  }
}
/* TOP ENGAGERS */
/* Label story */
.label-story {
  position: absolute;
  top: 43px;
  left: 20px;
  z-index: 1;
}
.label-story .btn {
  height: 25px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding-inline: 10px 14px;
}
.label-story > .btn svg {
  margin-right: 5px;
}
.label-set .btn {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
}
.label-container {
  position: absolute;
  top: calc(100% + 4px);
  width: 356px;
  z-index: 1;
  border: none;
  box-shadow: 0px 4px 4px #000;
}
.header-label-list h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.header-label-list {
  padding-bottom: 23px;
}
.header-label-list .btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  margin-left: auto;
  height: 30px;
}
.header-label-body li {
  border: 1px solid transparent;
  box-shadow: 0px 4px 20px transparent;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(187, 196, 215, 0.5);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding-inline: 15px 10px;
}
.header-label-body .btn {
  background: transparent;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.header-label-body .btn-edit-label {
  margin-left: auto;
}
.header-label-body .btn-delete-label {
  margin-left: 12px;
}
.header-label-body li:hover {
  background: rgba(24, 119, 242, 0.05);
  border: 1px solid #1877f2;
  border-radius: 10px;
  color: #1877f2;
}
.header-label-body li.active {
  background: #1877f2;
  border: 1px solid #1877f2;
  box-shadow: 0px 4px 20px rgba(0, 111, 255, 0.1);
  border-radius: 10px;
  color: #fff;
}
.header-label-body li:not(.active) .btn-edit-label:hover {
  background: #1877f2;
}
.header-label-body li:not(.active) .btn-delete-label:hover {
  background: #f00;
}
.header-label-body .btn-edit-label:hover path {
  fill: #fff;
}
.header-label-body .btn-delete-label:hover svg * {
  stroke: #fff;
}
.header-label-body li.active .btn {
  display: none;
}
.label-count {
  background: #1877f2;
  border-radius: 100px;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  padding-inline: 15px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  vertical-align: text-bottom;
  margin-left: 10px;
}
.header-label-body .creator-label {
  padding-inline: 10px 0;
  border-top: 1px solid rgba(187, 196, 215, 0.5);
  display: flex;
}
.creator-label input[type="text"] {
  flex: 1;
  border: none;
  height: 100%;
  padding: 0;
}
.creator-label .btn {
  width: auto;
  padding-inline: 17px;
  height: 100%;
}
.dashboard-content .stories:nth-child(5n + 5) .label-container {
  left: auto;
  right: 0;
}
/* Label story */
/* Setup automation */
.accordion-outer {
  padding: 30px 40px;
}
.accordion-header {
  padding-bottom: 20px;
}
.accordion-header h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #191a25;
}
.accordion-header .custom-check {
  margin-right: 15px;
}
.accordion-outer {
  border-radius: 10px;
}
.story-shots .stories {
  flex: 0 0 129px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(
    7.25deg,
    #000 5.6%,
    rgba(0, 0, 0, 0.55) 26.16%,
    rgba(0, 0, 0, 0) 46.25%,
    rgba(0, 0, 0, 0.44) 72.08%,
    #000 100%
  );
}
.story-items {
  background: #fff;
  border: 1px solid #bbc4d7;
  border-radius: 20px;
  position: relative;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 25%;
  padding: 20px 30px;
  transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  overflow: hidden;
}
.items-image {
  position: absolute;
  bottom: -9px;
  z-index: 0;
  left: 0;
}
.story-items:hover {
  background: #1877f2;
  border-color: #1877f2;
}
.story-items span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  margin-bottom: 12px;
  display: block;
}
.story-items h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.005em;
  color: #1877f2;
}
.story-items:hover span {
  color: #cce7ff;
}
.story-items:hover h3 {
  color: #fff;
}
.story-items .btn {
  position: absolute;
  right: 14px;
  bottom: 14px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #3c90ff;
  visibility: hidden;
  display: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.story-items:hover .btn {
  display: block;
  visibility: visible;
}
.story-infos {
  margin-left: auto;
  gap: 10px;
  max-width: calc(100% - (129px + 37px));
}
.content-setup .content-space {
  padding-inline: 100px;
}
.go-back {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 100%;
  margin-right: 30px;
}
.go-back:hover {
  background-color: #06f;
}
.go-back:hover path {
  fill: #fff;
}
.story-items p {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.story-items p svg {
  margin-right: 10px;
  flex: 0 0 18px;
}
.story-items p span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  color: #1877f2;
  margin: 0;
}
.story-viewers .table-cell-header:first-child {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #191a25;
  height: auto;
}
.story-viewers .table-cell-header:last-child {
  font-weight: 600;
  letter-spacing: 0.005em;
}
.accordionOuter {
  border: none;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.accordion-header .btn {
  border-radius: 100px;
  height: 42px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 28px;
}
.story-shots {
  width: 100%;
}
.automation-option {
  flex: 1 1 25%;
  background: #f0f3f9;
  border-radius: 5px;
  max-width: 25%;
  padding: 14px 40px 14px 20px;
  cursor: pointer;
  user-select: none;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.automation-option:hover,
.automation-option.active {
  background-color: #11aa3c;
  color: #fff;
}
.automation-message-select {
  gap: 10px;
}
.options-automation {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  padding: 10px 12px;
  background: #fff;
  border-radius: 5px;
  z-index: 1;
}
.automation-option:after {
  position: absolute;
  content: "";
  right: 15px;
  border-right: 2px solid #323232;
  border-bottom: 2px solid #323232;
  width: 8px;
  height: 8px;
  rotate: 45deg;
  top: 36px;
}
.automation-option:hover:after,
.automation-option.active:after {
  border-color: #fff;
  pointer-events: none;
}
.automation-option h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
}
.automation-option p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.options-automation li {
  color: #191a25;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 5px;
}
.options-automation li:hover {
  background-color: #009718;
  color: #fff;
}
.accordion-body > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  margin-bottom: 14px;
}
.automation-message-select.active p {
  overflow: visible;
  text-overflow: unset;
  max-width: initial;
  white-space: normal;
}
.options-created-automation {
  margin-left: 5px;
  position: relative;
}
.automation-options .option-automation {
  background: transparent;
  padding: 0;
}
.label-automation-active {
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 0.005em;
  padding-inline: 6px;
  height: 25px;
  margin-left: 15px;
}
.options-list-automation {
  position: absolute;
  right: 0;
  width: 100px;
  padding: 8px 10px;
  border-radius: 5px;
  box-shadow: 0 0 8px #000;
  z-index: 1;
  background: #fff;
  font-size: 14px;
  line-height: 21px;
}
.options-list-automation li {
  padding: 5px 0;
  cursor: pointer;
  font-weight: 500;
}
.options-list-automation li:hover {
  color: #06f;
}
.accordion-header .btn-blue {
  margin-left: auto;
}
.accordion-header .btn-gradient {
  margin-left: 15px;
}
.body-story figure a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/* Setup automation */
/* MESSAGE CREATION */
.content-container-message .content-space {
  overflow: hidden;
  padding: 20px 49px;
}
.messages-body {
  overflow: hidden;
}
.messages-header {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  margin-bottom: 19px;
  padding: 15px 30px;
  flex: 0 0 80px;
}
.mesage-previews,
.message-triggers {
  background: #fff;
  border-radius: 10px;
}
.mesage-previews {
  overflow: hidden;
}
.message-triggers {
  flex: 1 0 471px;
  max-width: 471px;
  margin-right: 30px;
}
.message-tab-trigger {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #7d8fb3;
  height: 48px;
  background: transparent;
  position: relative;
  padding: 0 20px;
}
.message-tab-trigger:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  bottom: -16px;
  left: 0;
  pointer-events: none;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
  -webkit-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
  -moz-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
  -ms-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
  -o-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
}
.message-tab-trigger:hover,
.message-tab-trigger.active {
  color: #1877f2;
}
.message-tab-trigger.active:after {
  background: #1877f2;
}
.message-tab-trigger.active:hover:after {
  bottom: 0;
}
.message-trigger-header,
.message-preview-header {
  flex: 0 0 80px;
  padding-inline: 30px;
  border-bottom: 1px solid #bbc4d7;
}
.message-trigger-header h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #191a25;
}
.message-trigger-header .message-count {
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.1);
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.005em;
  color: #1877f2;
  height: 26px;
  padding-inline: 12px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  margin-left: 10px;
}
.message-preview-header h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.message-options .btn {
  border: 1px solid #bbc4d7;
  border-radius: 5px;
  background: transparent;
  height: 42px;
  padding-inline: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  vertical-align: middle;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.message-options .btn:hover {
  opacity: 0.7;
}
.message-options .btn span {
  display: inline-flex;
  vertical-align: text-bottom;
  align-items: center;
  margin-right: 5px;
}
.message-options .btn + .btn {
  margin-left: 10px;
}
.message-triggersList {
  padding: 20px 0 20px 30px;
  overflow: auto;
  margin-right: 10px;
}
.message-trigger h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #191a25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-trigger {
  height: 90px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -ms-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  border-bottom: 1px solid rgba(187, 196, 215, 0.3);
}
.timestamp-trigger {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #191a25;
  opacity: 0.4;
  margin-top: auto;
}
.message-trigger.active,
.message-trigger:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-bottom-color: transparent;
}
.message-trigger.active h6,
.message-trigger:hover h6 {
  color: #1877f2;
}
.message-trigger.active .timestamp-trigger {
  opacity: 0.5;
}
.message-preview-body {
  padding: 20px 30px;
  overflow: auto;
  position: relative;
}
.message-empty-area {
  inset: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #06f;
}
.message-segment-item {
  display: inline-block;
  background: #1877f2;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #fff;
  padding: 7px 17px;
  height: 40px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.message-segment-item + .message-segment-item {
  margin-left: 10px;
}
.message-segment-item span {
  background: #4b9aff;
  padding-inline: 10px;
  border-radius: 30px;
  display: inline-flex;
  height: 27px;
  align-items: center;
  line-height: 1;
  margin-inline: 7px;
}
.content-container-message {
  overflow: hidden;
}
.search-container input {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.08);
  background-image: url("../images/ico-search.png");
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: right 15px center;
  border: none;
  height: 40px;
  width: 411px;
  color: #4d5e80;
  padding-right: 45px;
}
input::-webkit-search-cancel-button {
  display: none;
}
.search-container {
  margin-right: 20px;
}
.message-header-options .btn-create {
  border-radius: 5px;
  height: 40px;
  padding-inline: 20px;
  font-size: 16px;
  line-height: 19px;
}
.message-modal-outer {
  max-width: 792px;
}
.message-modal-outer .modal-body {
  padding: 24px 30px 40px;
}
fieldset.form-group {
  background: #fff;
  border: 1px solid #bbc4d7;
  border-radius: 5px;
  padding: 5px 24px 0;
  height: 50px;
}
fieldset.form-group:hover,
fieldset.form-group:focus {
  border-color: #1877f2;
}
fieldset.form-group .form-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #191a25;
  margin: 0;
}
fieldset.form-group .form-control {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}
fieldset.form-message-block {
  display: flex;
  height: 488px;
  flex-direction: column;
  padding-bottom: 20px;
  overflow: auto;
}
.form-message-block .form-element {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
}
.form-message-blocks {
  flex: 1 1 auto;
  overflow: auto;
  padding-top: 16px;
}
.form-message-block input.form-control {
  background: rgba(187, 196, 215, 0.2);
  border-radius: 5px;
  height: 40px;
  width: auto;
  flex: 1 1 auto;
  padding: 0 20px;
}
.form-message-block .form-element .btn {
  padding: 0 27px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  margin-left: 10px;
}
.fast-tags {
  margin-top: 20px;
}
.fast-tags .message-tags {
  height: 31px;
  left: 938px;
  top: 832px;
  background: #f2ecff;
  border: 1px solid #e8deff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #7337ff;
  display: inline-flex;
  align-items: center;
  padding-inline: 10px;
  margin-right: 5px;
  cursor: pointer;
}
.fast-tags .message-tags:hover {
  background-color: #7337ff;
  color: #fff;
}
button:disabled {
  background: #bbc4d7;
  pointer-events: none;
}
.message-modal-outer .form-footer .btn {
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.005em;
  padding-inline: 30px;
  height: 40px;
}
.message-block {
  display: inline-flex;
  align-items: center;
  background: #1877f2;
  border-radius: 100px;
  color: #fff;
  padding-inline: 20px 52px;
  position: relative;
  min-height: 40px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.message-block .delete-message-block {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 3px;
}
.message-block span {
  background: #4b9aff;
  border-radius: 100px;
  display: inline-flex;
  padding-inline: 10px;
  height: 31px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.005em;
  margin-left: 10px;
}
.message-block:hover .delete-message-block {
  background-color: #fff;
}
.message-block:hover .delete-message-block path {
  stroke: #f00;
}
.message-preview-group {
  margin: 30px auto;
  width: 606px;
  height: 793px;
  overflow: hidden;
  background-image: url("../images/message-group-outer.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  flex-direction: column;
  padding: 42px;
  padding-bottom: 84px;
  padding-top: 124px;
}
.new-message-group.d-flex {
  order: 2;
}
.message-group-reply {
  order: 1;
  margin-top: auto;
}
.message-preview-group .message-segment-item {
  background: #1877f2;
  border-radius: 20px 20px 5px 20px;
  max-width: 356px;
  display: inline;
  height: auto;
  white-space: normal;
  margin-left: auto;
  padding: 20px 45px 30px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
}
.new-message-group .user-image {
  width: 44.16px;
  height: 44.16px;
  border-radius: 50%;
  margin-left: 6px;
  margin-top: auto;
  margin-bottom: -25px;
}
.form-mesage-opt {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: top;
}
.btn-select-type {
  /* background: #753AFF;
	 */
  border-radius: 5px;
  height: 30px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  padding: 0 10px;
  /* padding-inline: 10px 30px;
	 */
}
.select-message-type {
  vertical-align: top;
  border-radius: 5px;
  margin-left: 5px;
  width: 171px;
}
.select-message-type ul {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 11px 25px rgba(0, 0, 0, 0.1);
  padding: 4px;
  max-height: 158px;
  overflow: auto;
}
.select-message-type .btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #737698;
  display: flex;
  justify-content: start;
  border-radius: 4px;
  height: 30px;
  width: 100%;
  padding-inline: 11px;
  background: #fff;
}
.select-message-type .btn:hover {
  background: #ebf4ff;
  color: #1877f2;
}
.select-message-type input {
  background: #fff;
  border: 1px solid #d7e0ed;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  color: #1b2648;
}
.group-block {
  display: inline-flex;
  align-items: center;
  padding-inline: 10px 30px;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #fff;
  background: #753aff;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 30px;
}
.group-block:hover {
  color: #753aff;
  background-color: #fff;
}
.delete-message-block {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;
}
.delete-message-block svg path {
  stroke: #753aff;
}
.header-stories-engaged .select-range-insights {
  margin-left: auto;
}
.header-stories-engaged {
  margin-bottom: 20px;
}
.engager-info-short {
  flex: 1 1 auto;
  max-width: 709px;
  height: 160px;
  margin-right: 20px;
}
.engager-info-short {
  background: #1877f2;
  border: 1px solid #1877f2;
  border-radius: 10px;
  padding: 25px 30px;
  position: relative;
}
.engager-info-short figure {
  flex: 0 0 100px;
  height: 100px;
  border-radius: 50%;
}
.engager-info-short figure {
  flex: 0 0 100px;
  height: 100px;
  border-radius: 50%;
  background: #ccc;
  margin-right: 20px;
}
.engager-info-short .facebook-bg {
  position: absolute;
  right: 20px;
}
.engager-badge {
  border-radius: 100px;
  padding-inline: 10px;
  height: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  color: #000;
  background: #fff;
  display: inline-flex;
  align-items: center;
}
.top-engager-badge {
  background: #ffe924;
}
.engager-info h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #fff;
  margin: 10px 0;
}
.engager-info a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  color: #fff;
}
.engager-info .open-link {
  margin-left: 5px;
}
.engager-shots .items-image {
  right: 10px;
  left: auto;
}
.engager-shots .story-items span {
  color: #7d8fb3;
  margin-bottom: 5px;
}
.engager-shots .story-items h4 + span {
  margin-top: 16px;
}
.engager-shots .story-items h4:last-of-type:not(:first-of-type) {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.005em;
  color: #1877f2;
}
.engager-shots .story-items:hover span,
.engager-shots .story-items:hover h4 {
  color: #fff !important;
}
.engager-stories-header {
  position: relative;
  margin-bottom: 30px;
}
.engager-stories-header h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #4d5e80;
}
.engager-stories-header h5 span {
  background: linear-gradient(85.26deg, #1a68ff -1.97%, #9c1fff 90.96%);
  border-radius: 100px;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding-inline: 10px;
  margin-left: 10px;
  vertical-align: 3px;
}
.date-span-engaged {
  background: #fff;
  box-shadow: 0px 2px 10px #000;
  border-radius: 100px;
  padding-inline: 11px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #4d5e80;
  height: 30px;
}
.date-span-engaged svg {
  margin-right: 6px;
}
.viewed-info {
  display: inline-flex;
  vertical-align: top;
  padding: 10px;
  background: #f2f9ff;
  border: 1px solid #c5d1dc;
  border-radius: 15px;
  flex: 0 1 calc(33.333% - (19px * 2));
}
.viewed-stories {
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
}
.viewed-info .stories {
  flex: 0 0 216px;
  margin-right: 40px;
}
.viewed-info label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  color: #4d5e80;
  margin-bottom: 5px;
  display: flex;
}
.viewed-info label:not(:first-child) {
  margin-top: 30px;
}
.viewed-info h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #1877f2;
}
.viewed-info p {
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.005em;
  color: #7d8fb3;
}
.viewed-info p .trigger-open {
  color: #1877f2;
  font-weight: 600;
  display: inline-flex;
  cursor: pointer;
}
.engager-stories-header:after {
  position: absolute;
  content: "";
  width: 71%;
  height: 1px;
  background: #bbc4d7;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -1px;
  z-index: -1;
}
.btn-add-groupItem {
  max-width: 150px;
  margin-left: auto;
  padding-inline: 15px;
  border-radius: 8px;
  height: 42px;
  font-size: 15px;
}
fieldset.form-message-created {
  height: auto;
  min-height: 55px;
  padding-bottom: 10px;
}
/* MESSAGE CREATION */
.no-content-listing {
  display: flex;
  min-height: 150px;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  width: 100%;
  border-radius: 8px;
  border: 2px dashed #1a68ff;
  font-size: 20px;
  background: rgba(26, 104, 255, 0.03);
  color: #1a68ff;
}
.exportButton {
  position: relative;
  left: 0;
  margin-left: 80%;
  cursor: pointer;
}
.btn-start-engagement {
  position: relative;
  overflow: hidden;
}
.btn-start-engagement.working {
  /* background: #00266E;
	 */
  /* background: linear-gradient(90deg, #FF1A27 0%, #FF858C 100%);
	 */
  background: repeating-linear-gradient(
    90deg,
    #ff1a27 0%,
    #ff858c 50%,
    #ff1a27 100%
  );
  background-size: 200% 100%;
  animation: gradientFlow 5s infinite alternate;
}
@keyframes gradientFlow {
  0% {
    background-position: left 0% center;
  }
  100% {
    background-position: left 100% center;
  }
}
/* .btn-start-engagement:after {
	 position: absolute;
	 content: '';
	 width: 2000%;
	 left: 0;
	 bottom: -100%;
	 background-image: url('../images/wave.svg');
	 background-repeat: repeat-x;
	 height: 100%;
	 background-position: left bottom;
	 transform: translateX(0);
	 pointer-events: none;
}
 */
/* .btn-start-engagement.working:after {
	 bottom: 0;
	 animation: waveIt 50s infinite alternate;
}
 @keyframes waveIt {
	 to {
		 transform: translateX(-93%);
	}
}
 */
.btn-header-settings {
  padding-inline: 0;
  width: 41px;
  height: inherit;
  aspect-ratio: 1;
  margin-left: 20px;
}
.engagement-settings {
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  left: auto;
  /* width: 410px;
	 */
  width: 535px;
  background: #fff;
  z-index: 2;
  padding: 14px 18px;
  padding-right: 0;
}
.engagement-settings-header {
  padding-block-end: 15px;
  margin-block-end: 10px;
  margin-inline-end: 10px;
  border-bottom: 1px solid #dee4f2;
}
.engagement-settings-footer {
  margin-top: auto;
  padding-block-start: 15px;
}
.set-reactions {
  overflow: auto;
}
.set-reactions > * {
  margin-right: 18px;
}
.set-reactions ul {
  /* row-gap: 15px;
	 */
  flex-wrap: wrap;
  gap: 15px;
}
.set-reactions li {
  padding-inline: 10px;
  border-radius: 5px;
  display: flex;
  background: #ecf2fa;
  /* flex: 0 1 50px;
	 */
  height: 50px;
  flex: 0 1 calc(50% - 7.5px);
  align-items: center;
  column-gap: 10px;
  padding: 10px;
}
.engagement-settings-header .btn {
  background: transparent;
  padding: 0;
  line-height: 1;
}
.set-reactions li input {
  margin-inline-start: auto;
  border-radius: 5px;
  width: 60px;
  height: 30px;
  color: #31353b;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #bfc8dc;
  background: var(--White, #fff);
  font-family: "Inter", sans-serif;
  padding-inline: 5px;
}
.set-reactions li label {
  color: var(--Text-primary, #31353c);
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.set-reactions li input:focus {
  border: 1px solid var(--Primary-blue, #1877f2);
  background: var(--White, #fff);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.engagement-settings-footer .btn {
  height: 39px;
  padding-inline: 20px;
  margin-inline-start: 10px;
}
.engagement-settings-footer .btn:not(.btn-gradient) {
  border-radius: 100px;
  border: 1px solid var(--Button_Gradient, #1a68ff);
  background: transparent;
}
.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-container {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  color: initial !important;
}
.Toastify__toast-icon {
  display: none !important;
}
.Toastify {
  position: fixed;
  z-index: 99;
}
.Toastify__toast-container--bottom-right {
  right: 50px !important;
  bottom: 30px !important;
  width: 340px !important;
}
.alert-divider {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7px;
  left: 0;
  width: 100%;
  animation: shrink 5s ease 1 forwards;
}
@keyframes shrink {
  to {
    width: 0;
  }
}
.alert-success .alert-divider {
  background: #b9e5bc;
}
.alert-inner {
  width: 100%;
  border-radius: 8px;
  padding: 16px 20px;
  position: relative;
  font-family: "Inter", sans-serif;
}
.alert-success {
  background-color: #dafedc;
}
.msg-header {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.alert-success .msg-header {
  color: #218c26;
}
.msg {
  color: var(--Text-primary, #31353c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Inter", sans-serif;
  margin-top: 8px;
}
.Toastify .Toastify__close-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 61px;
  height: 100%;
  background-image: url("../images/close-circle.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.Toastify .Toastify__close-button svg {
  display: none;
}
.Toastify .Toastify__close-button:before {
  content: "";
  position: absolute;
  left: 0;
  height: 44px;
  top: 50%;
  transform: translateY(-50%);
  background: #b9e5bc;
  width: 1px;
}
.settings-sections {
  margin-block-start: 30px;
}
.settings-sections h6 {
  color: #31353c;
  font-size: 16px;
  font-weight: 500;
  margin-block-end: 10px;
}
.select-default {
  border: 1px solid #d2dae9;
  background-color: #fff;
  background-image: url("../images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  width: 100%;
  height: 34px;
  padding-inline: 10px;
  border-radius: 5px;
  color: #6f737a;
  font-size: 12px;
  font-weight: 400;
}
.select-default :focus {
  border-color: #1877f2;
}
.radio-inline {
  display: inline-flex;
  column-gap: 10px;
}
.radio-inline label {
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  color: #31353c;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.radio-inline label input {
  margin: 0;
  padding: 0;
}
.muted-text {
  color: #5680e2;
}
.header-note {
  margin-bottom: 15px;
  column-gap: 10px;
}
.p-pic {
  flex: 0 0 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  margin: 2px 0 2px 2px;
}
.friend-profile-pic {
  flex: 1 1 auto;
  overflow: hidden;
}
.friend-profile-pic article {
  margin-left: 14px;
  white-space: nowrap;
  color: #fff;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-pic-status {
  box-shadow: 0 0 0 2px #1877f2;
}
.friend-profile-pic article h6 {
  font-size: 14px;
  line-height: 16.8px;
  margin-bottom: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.friend-profile-pic article p {
  color: #afb6c1;
  font-size: 10px;
  line-height: 12px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer-story {
  z-index: 1;
}
.body-story:after {
  position: absolute;
  content: "";
  background: linear-gradient(
    17.14deg,
    rgba(0, 0, 0, 0.9) 0.42%,
    rgba(0, 0, 0, 0.09) 51.6%,
    rgba(0, 0, 0, 0.9) 100%
  );
  inset: 0;
  pointer-events: none;
  border-radius: 10px;
}
.num-stats {
  column-gap: 5px;
  padding-inline: 8px 5px;
  border-radius: 30px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  align-items: center;
  color: #fff;
  border: 1px solid #fff 33;
  margin-inline-start: 5px;
  display: none;
  /* @media (max-width:1600px) {
		 display: none;
	}
	 */
}
.engage-check {
  padding-inline: 8px 5px;
  border-radius: 30px;
  height: 34px;
  margin: 15px 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  /* pointer-events: none;
	 */
}
.engage-check p {
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  display: inline-flex;
  align-items: center;
}
.engage-check p strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  color: rgba(255, 255, 255, 0.5);
  margin-inline-end: 5px;
}
.engage-check p strong span {
  color: rgba(255, 255, 255, 1);
}
.engage-check svg rect {
  fill-opacity: 0.4;
}
.engage-check.engage-pending {
  background: rgba(255, 155, 5, 0.8);
}
.engage-check.engage-completed {
  background: rgba(77, 250, 82, 0.7);
}
.new-insights {
  background: #6dd8f0;
  color: #313a45;
  align-items: flex-start;
  height: inherit;
}
.new-insights figure {
  background: #64cbe2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 10px;
}
.new-insights h3 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}
.new-insights h6 {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
  margin-block: 8px;
}
.new-insights:nth-child(2),
.new-insights:nth-child(5) {
  background: #6df0b1;
}
.new-insights:nth-child(2) figure,
.new-insights:nth-child(5) figure {
  background: #50d796;
}
.new-insights:first-child {
  background: #6df0e8;
  background-image: url("../../../public/images/insights-mask.png");
  background-repeat: no-repeat;
  background-position: right -60px bottom -22px;
  flex: 1 1 100%;
}
.new-insights:first-child figure {
  background: #52dbd3;
}
