.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  position: fixed;
  inset: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.inline-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: inline-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0066ff transparent transparent transparent;
}
.inline-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.inline-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.inline-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes inline-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inline-loader-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  position: relative;
  margin: 15px 0;
}
.inline-loader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.extension-installation {
  position: relative;
}

.pointer-arrow {
  position: absolute;
  width: 85px;
  height: auto;
  top: -190px;
  left: 100%;
}
.extension-installation p {
  max-width: 308px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7D8FB3;
  margin-inline: auto;
  margin-top: 50px;
}

.extension-installation p  a {
  color: #0066ff;
  font-weight: 600;
}
.errorPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorPage h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #4D5E80;
  margin-bottom: 20px;
  margin-top: -100px;
}
.errorPage p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4D5E80;
}
.button-footer {
  margin-top: 40px;
}
.redirectButton {
  background: #0066FF;
  border-radius: 10px;
  border: none;
  height: 60px;
  padding-inline: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #FFFFFF;
}
.redirectButton:hover {
  background-color: #3c86f5;
}
.redirectButton .ico-right {
  display: inline-flex;
  margin-left: 17px;
}
.redirectButton .ico-left {
  display: inline-flex;
  margin-right: 17px;
}
.errorPage footer {
  margin-top: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4D5E80;
  position: absolute;
  bottom: 37px;
}
.errorPage footer a,
.errorPage p a {
  color: #0066FF;
}

.sk-loader {
  position: relative;
  background: rgb(223 223 223) !important;
  overflow: hidden;
  border: none !important;
}


.sk-loader::before {
  position: absolute;
  inset: 0;
  z-index: 8;
  content: '';
  background: rgb(223 223 223) !important;
}
.sk-loader::after {
  display: block;
  content: "";
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(0, 0, 0, 0.03)), to(transparent)) !important;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.03), transparent) !important;
  /*Addinganimation*/
  animation: loading 0.8s infinite;
  -webkit-animation: loading 0.8s infinite;
  z-index: 9;
}


@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}